export const ACTION_APPLICATION_ERROR = 'ACTION_APPLICATION_ERROR';

export const ACTION_SIDEBAR_GET_DETAILS_SUCCESS =
  'ACTION_SIDEBAR_GET_DETAILS_SUCCESS';
export const ACTION_SIDEBAR_GET_DETAILS_FAILURE =
  'ACTION_SIDEBAR_GET_DETAILS_FAILURE';

export const ACTION_MANAGE_LEADS_GET_DETAILS_SUCCESS =
  'ACTION_MANAGE_LEADS_GET_DETAILS_SUCCESS';
export const ACTION_MANAGE_LEADS_GET_DETAILS_FAILURE =
  'ACTION_MANAGE_LEADS_GET_DETAILS_FAILURE';

export const ACTION_SHOPPING_CART_GET_DETAILS_SUCCESS =
  'ACTION_SHOPPING_CART_GET_DETAILS_SUCCESS';
export const ACTION_SHOPPING_CART_GET_DETAILS_FAILURE =
  'ACTION_SHOPPING_CART_GET_DETAILS_FAILURE';

export const ACTION_SHOPPING_CART_REMOVE_LEADORDER_ITEM_SUCCESS =
  'ACTION_SHOPPING_CART_REMOVE_LEADORDER_ITEM_SUCCESS';
export const ACTION_SHOPPING_CART_REMOVE_LEADORDER_ITEM_FAILURE =
  'ACTION_SHOPPING_CART_REMOVE_LEADORDER_ITEM_FAILURE';
export const ACTION_LEAD_GROUPS_GET_DETAILS_SUCCESS =
  'ACTION_LEAD_GROUPS_GET_DETAILS_SUCCESS';
export const ACTION_LEAD_GROUPS_GET_DETAILS_FAILURE =
  'ACTION_LEAD_GROUPS_GET_DETAILS_FAILURE';

export const ACTION_PAYMENT_METHODS_GET_DETAILS_SUCCESS =
  'ACTION_PAYMENT_METHODS_GET_DETAILS_SUCCESS';
export const ACTION_PAYMENT_METHODS_GET_DETAILS_FAILURE =
  'ACTION_PAYMENT_METHODS_GET_DETAILS_FAILURE';

export const ACTION_SHOPPING_CART_APPLY_COUPON_SUCCESS =
  'ACTION_SHOPPING_CART_APPLY_COUPON_SUCCESS';
export const ACTION_SHOPPING_CART_APPLY_COUPON_FAILURE =
  'ACTION_SHOPPING_CART_APPLY_COUPON_FAILURE';

export const ACTION_STATE_GET_DETAILS_SUCCESS =
  'ACTION_STATE_GET_DETAILS_SUCCESS';
export const ACTION_STATE_GET_DETAILS_FAILURE =
  'ACTION_STATE_GET_DETAILS_FAILURE';

export const ACTION_HEATMAP_GET_DETAILS_SUCCESS =
  'ACTION_HEATMAP_GET_DETAILS_SUCCESS';
export const ACTION_HEATMAP_GET_DETAILS_FAILURE =
  'ACTION_HEATMAP_GET_DETAILS_FAILURE';

export const ACTION_ADD_CARD_SUCCESS = 'ACTION_ADD_CARD_SUCCESS';
export const ACTION_ADD_CARD_FAILURE = 'ACTION_ADD_CARD_FAILURE';

export const ACTION_MAKE_DEFAULT_CARD_SUCCESS =
  'ACTION_MAKE_DEFAULT_CARD_SUCCESS';
export const ACTION_MAKE_DEFAULT_CARD_FAILURE =
  'ACTION_MAKE_DEFAULT_CARD_FAILURE';

export const ACTION_DELETE_CARD_SUCCESS = 'ACTION_DELETE_CARD_SUCCESS';
export const ACTION_DELETE_CARD_FAILURE = 'ACTION_DELETE_CARD_FAILURE';

export const ACTION_ADD_QUATITY_SUCCESS = 'ACTION_ADD_QUATITY_SUCCESS';
export const ACTION_ADD_QUATITY_FAILURE = 'ACTION_ADD_QUATITY_FAILURE';

export const ACTION_ADD_SHOPPINGCART_SUCCESS =
  'ACTION_ADD_SHOPPINGCART_SUCCESS';

export const ACTION_SET_LEADS_REMINDERS = 'ACTION_SET_LEADS_REMINDERS';
export const SET_TAB_ON_LEADS = 'SET_TAB_ON_LEADS';

export const GET_REALTIMELEADS_CAMPAIGNS_LIST_SUCCESS =
  'GET_REALTIMELEADS_CAMPAIGNS_LIST_SUCCESS';
export const GET_REALTIMELEADS_CAMPAIGNS_LIST_FAILURE =
  'GET_REALTIMELEADS_CAMPAIGNS_LIST_FAILURE';

export const ACTION_SET_GREETING = 'ACTION_SET_GREETING';

export const ACTION_SET_CART_WITH_DISCOUNT = 'ACTION_SET_CART_WITH_DISCOUNT';
export const ACTION_SET_DISCOUNT_FLAG = 'ACTION_SET_DISCOUNT_FLAG';
export const DELETE_CARTITEM_FROM_DROPDOWN = 'DELETE_CARTITEM_FROM_DROPDOWN';
export const ACTION_SET_COUPON_DETAILS = 'ACTION_SET_COUPON_DETAILS';
export const ACTION_SET_CUSTOM_AD_CAMPAIGN_TYPES =
  'ACTION_SET_CUSTOM_AD_CAMPAIGN_TYPES';
export const ACTION_SET_CREATIVE_NAMES = 'ACTION_SET_CREATIVE_NAMES';

// Actions for permissions
export const SET_REALTIMELEADS = 'SET_REALTIMELEADS';
export const SET_ADCAMPAIGNS = 'SET_ADCAMPAIGNS';
export const SET_CANVIEWLEADALL = 'SET_CANVIEWLEADALL';
export const SET_CANMANAGELEADREFUND = 'SET_CANMANAGELEADREFUND';
export const SET_CANUPDATELEAD = 'SET_CANUPDATELEAD';
export const SET_CANVIEWUSER = 'SET_CANVIEWUSER';

//manage agents redesign
export const USERS_SEARCH_REQUEST = 'USERS_SEARCH_REQUEST';
export const USERS_SEARCH_SUCCESS = 'USERS_SEARCH_SUCCESS';
export const USERS_SEARCH_FAILURE = 'USERS_SEARCH_FAILURE';
export const UPDATE_USERS_SEARCH_PARAMS = 'UPDATE_USERS_SEARCH_PARAMS';
