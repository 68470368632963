import {
  UPDATE_USERS_SEARCH_PARAMS,
  USERS_SEARCH_FAILURE,
  USERS_SEARCH_REQUEST,
  USERS_SEARCH_SUCCESS,
} from 'src/common/constants/actionTypes';

const initialState = {
  loading: false,
  data: null,
  totalCount: 0,
  error: null,
  searchParams: {
    sorting: { sortingColumn: 'modifiedDate', isDescSort: true },
    paging: { startRowIndex: 0, pageSize: 20 },
    isApproved: true,
  },
};

const usersSearchReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_USERS_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: {
          ...state.searchParams,
          ...action.payload,
        },
      };
    case USERS_SEARCH_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case USERS_SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        totalCount: action.payload.totalCount,
      };
    case USERS_SEARCH_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default usersSearchReducer;
